import SectionHeader from '../components/sections/header';
import SectionBreadcrumbs from '../components/sections/breadcrumbs';
import SectionProductSticky from '../components/sections/product-sticky';
import SectionProductSlider from '../components/sections/product-slider';
import SectionInstagram from '../components/sections/instagram';
import SectionFooter from '../components/sections/footer';

import React from 'react';
import { graphql, Script } from 'gatsby';

import { IPage } from '../models/page.model';
import { IProduct } from '../models/product.model';
import { ProductContextProvider } from '../contexts/product-content';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import { getStructuredProductData } from '../config/structured-data';

interface IPostProps {
    readonly data: {
        page: IPage;
        product: IProduct;
    };
}

const Product: React.FC<IPostProps> = ({ data }) => {
    const { page, product } = data;

    const structuredProductData = getStructuredProductData(product);
    const structuredProductDataString = JSON.stringify(structuredProductData);

    const sectionsWithData = page.sections
        .map((section) => {
            if (section.type === 'product-sticky') {
                return {
                    ...section,
                    extendedItems: {
                        product,
                    },
                };
            }

            if (section.type === 'product-slider') {
                if (product.relatedProducts && product.relatedProducts.length === 0) return null;

                return {
                    ...section,
                    items: {
                        products: product.relatedProducts,
                    },
                };
            }
            return section;
        })
        .filter(nonNullable);

    return (
        <MainLayout>
            <Script type="application/ld+json">{structuredProductDataString}</Script>
            <ProductContextProvider>
                {sectionsWithData.map((section) => {
                    return (
                        <SectionFactory
                            key={`section-${section.type}-${section.sectionId}`}
                            SectionComponent={sectionComponents[section.type]}
                            section={section}
                            TitleTag={section.isMain ? 'h1' : 'h2'}
                        />
                    );
                })}
            </ProductContextProvider>
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $productId: Int!, $locale: String!) {
        page(pageId: { eq: $pageId }) {
            sections {
                ...sectionFields
            }
        }
        product(productId: { eq: $productId }, locale: { eq: $locale }) {
            ...productFields
            relatedProducts {
                ...productFields
            }
        }
    }
`;

function nonNullable<T>(value: T): value is NonNullable<T> {
    return Boolean(value);
}

export default Product;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'breadcrumbs': SectionBreadcrumbs,
    'product-sticky': SectionProductSticky,
    'product-slider': SectionProductSlider,
    'instagram': SectionInstagram,
    'footer': SectionFooter,
};