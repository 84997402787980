import { IProduct } from '../models/product.model';
import { getAllMediaByRelation } from '@alterpage/gatsby-plugin-image';
import { relations } from './relations';

const BASE_URL = process.env.BASE_URL;

export const structuredGeneralData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Kidaroo - sklep z zabawkami i akcesoriami dla dzieci i niemowląt',
    url: 'https://kidaroo.pl/',
    logo: 'https://api.kidaroo.pl/media/14_bez-nazwy-3.png/optimized/25',
    contactPoint: {
        '@type': 'ContactPoint',
        telephone: '+48606104884',
        contactType: 'customer service',
        areaServed: 'PL',
        availableLanguage: 'Polish',
    },
    sameAs: 'https://www.instagram.com/kidaroo_pl/',
};

export const structuredLocalBusinessData = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: 'Kidaroo - sklep z zabawkami i akcesoriami dla dzieci i niemowląt',
    image: 'https://api.kidaroo.pl/media/14_bez-nazwy-3.png/optimized/25',
    url: 'https://kidaroo.pl/',
    telephone: '+48606104884',
    priceRange: '$$',
    address: {
        '@type': 'PostalAddress',
        streetAddress: 'Obłoków 26',
        addressLocality: 'Szczecin',
        postalCode: '71-493',
        addressCountry: 'PL',
    },
    geo: {
        '@type': 'GeoCoordinates',
        latitude: 53.4767301,
        longitude: 14.5201698,
    },
    openingHoursSpecification: {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '09:00',
        closes: '17:00',
    },
    sameAs: 'https://www.instagram.com/kidaroo_pl/',
};

export const getStructuredProductData = (product: IProduct) => {
    let data: any = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: product.name,
        ...(product.description
            ? {
                  description: product.description.replace(/<[^>]*>?/gm, ''),
              }
            : {}),
        offers: {
            '@type': 'Offer',
            url: `${BASE_URL}${product.pathname}`,
            priceCurrency: 'PLN',
            price: product.regularPrice.gross,
            availability: 'https://schema.org/InStock',
            itemCondition: 'https://schema.org/NewCondition',
        },
    };

    const mainImage = getAllMediaByRelation(product.media, relations.mainImage);

    if (mainImage && mainImage[0] && mainImage[0].url) {
        data = {
            ...data,
            image: mainImage[0].url,
        };
    }

    if (product.manufacturer) {
        data = {
            ...data,
            brand: {
                '@type': 'Brand',
                name: product.manufacturer.name,
            },
        };
    }

    return data;
};
